<template>
  <b-row>
    <b-col cols="12" sm="8">
      <b-card no-header>
        <template slot="header">
          Edit Profile
        </template>
        <b-row class="form-group">
          <b-col md="6">
            <label>First Name</label>
            <b-form-input type="text" placeholder="First Name" v-model.trim="profile.contact.firstName"></b-form-input>
            <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
          </b-col>
          <b-col md="6">
            <label>Last Name</label>
            <b-form-input type="text" placeholder="Last Name" v-model.trim="profile.contact.lastName"></b-form-input>
            <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col md="6">
            <label>E-Mail Address</label>
            <b-form-input type="text" placeholder="" v-model.trim="profile.contact.emailAddress.address"></b-form-input>
            <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
          </b-col>
          <b-col md="4">
            <label>Username</label>
            <b-form-input type="text" placeholder="Username" v-model.trim="profile.username" autocomplete="off"></b-form-input>
            <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
          </b-col>
        </b-row>
        <template slot="footer">
          <b-row>
            <b-col sm="6"></b-col>
            <b-col sm="6" class="text-right">
              <b-button variant="primary" @click="save">Save</b-button>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-col>
    <b-col cols="12" sm="4">
      <b-card no-header>
        <template slot="header">
          Update Password
        </template>
        <b-row class="form-group">
          <b-col md="12">
            <b-form-group>
              <b-input-group>
                <b-form-input type="password" autocomplete="off" v-model="password" placeholder="Password" :state="state($v.password)" @input="touch($v.password)"></b-form-input>
                <b-input-group-append><b-input-group-text><i class="fa fa-asterisk"></i></b-input-group-text></b-input-group-append>
                <b-form-invalid-feedback>Password needs to be 8 characters</b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <b-input-group>
                <b-form-input type="password" autocomplete="off" v-model="verifyPassword" placeholder="Verify" :state="state($v.verifyPassword)" @input="touch($v.verifyPassword)"></b-form-input>
                <b-input-group-append><b-input-group-text><i class="fa fa-asterisk"></i></b-input-group-text></b-input-group-append>
                <b-form-invalid-feedback>Passwords should match</b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <template slot="footer">
          <b-row>
            <b-col sm="12" class="text-right">
              <b-button :disabled="$v.password.$invalid || $v.verifyPassword.$invalid" variant="primary" @click="savePassword">Save Password</b-button>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
let roles = []
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import { mapGetters } from 'vuex'

export default {
  name: 'Profile',
  props: {
    caption: {
      type: String,
      default: 'Profile id'
    },
  },
  data () {
    return {
      profile: {
        contact: {
          firstName: null,
          lastName: null,
          emailAddress: {
            address: null
          }
        },
        username: null,
        roleId: null,
        role: null,
        organizationId: null
      },
      password: null,
      verifyPassword: null
    }
  },
  computed: {
    ...mapGetters({
      account: 'global/account'
    })
  },
  methods: {
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    savePassword () {
      this.$api.accounts.update(this.profile.id, { password: this.password })
        .then((response) => {
          this.$snotify.success('Password Updated')
          this.password = null
          this.verifyPassword = null
          this.$nextTick(() => { this.$v.$reset() })
        })
        .catch((err) => {
          this.$snotify.error('Error Saving Password')
        })
    },
    goBack() {
      this.$router.go(-1)
      // this.$router.replace({path: '/users'})
    },
    save () {
      this.$api.accounts.update(this.profile.id, this.profile)
        .then((response) => {
          this.$snotify.success('Profile Saved Successfully')
          this.profile = response.data
          this.$nextTick(() => { this.$v.$reset() })
        })
        .catch((err) => {
          this.$snotify.error('Error Saving Account')
        })
    }
  },
  watch: {
    account: {
      handler: function (account) {
        this.profile = Object.assign(this.profile, this.account)
      },
      deep: true
    }
  },
  created () {
    this.$store.dispatch('global/getAccount')
  },
  validations: {
    password: {
      required,
      minLength: minLength(8)
    },
    verifyPassword: {
      required,
      sameAsPassword: sameAs('password')
    }
  }
}
</script>
